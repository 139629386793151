// extracted by mini-css-extract-plugin
export var centerJustify = "Content-module--center--justify--95257";
export var constrict = "Content-module--constrict--f5436";
export var content = "Content-module--content--4f802";
export var leftJustify = "Content-module--left--justify--73424";
export var lgSpacing = "Content-module--lg--spacing--20260";
export var mdSpacing = "Content-module--md--spacing--9e043";
export var noneSpacing = "Content-module--none--spacing--ed221";
export var rightJustify = "Content-module--right--justify--c73e6";
export var smSpacing = "Content-module--sm--spacing--3f607";
export var uniformHeadings = "Content-module--uniformHeadings--3da0e";
export var xsSpacing = "Content-module--xs--spacing--b0e77";