import React from 'react';

import Content from '@components/Layout/Content/Content';
import PageSection from '@components/Layout/PageSection/PageSection';
import Page from '@components/Master/Page';
import SEO from '@components/Master/SEO';
import Breadcrumbs from '@components/Molecule/Breadcrumbs/Breadcrumbs';
import Share from '@components/Molecule/Share/Share';
import H from '@components/Typography/H';

const PostTemplate = ({ pageContext }: { pageContext: any }) => {
	const { crumbs } = pageContext.breadcrumb;

	const { title, content } = pageContext.data;

	return (
		<Page>
			<SEO title={`${title?.text && title.text} | UHV Design`} />

			<PageSection>
				<Breadcrumbs crumbs={crumbs} />

				{title?.text && (
					<H level={2} as="h1">
						{title.text}
					</H>
				)}
			</PageSection>

			<PageSection>
				<Share title={title?.text} url={'/'} />

				<Content constrict>

					{content?.html && (
						<div
							dangerouslySetInnerHTML={{
								__html: content.html,
							}}></div>
					)}
				</Content>
			</PageSection>
		</Page>
	);
};

export default PostTemplate;
